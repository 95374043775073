import axios, { AxiosError } from "axios";
import { IWithMessages } from "./interfaces/IWithMessages";
import { APP_SECRET_KEY, IS_PRODUCTION, IS_LOCALHOST } from "./constants/ProjectConstants";

const _baseUrl = IS_PRODUCTION ? "https://api.fimdeanoatakarejo.com.br" : (IS_LOCALHOST ? "http://localhost:8080" : 'https://dev.fimdeanoatakarejo.com.br');
const api = axios.create({
  baseURL: _baseUrl + "/api",
  headers: {
    "Allow": "GET, POST, DELETE, HEAD, OPTIONS",
    "Access-Control-Allow-Origin": _baseUrl,
    "Access-Control-Allow-Methods": "GET, POST, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Authorization, Content-Type",
    "Content-type": "application/json",
    "Authorization": `Bearer ${APP_SECRET_KEY}`,
  }
});

api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response) {
      const errorMessages = error.response?.data as IWithMessages || JSON.stringify(error.response);
      return Promise.reject(errorMessages);
    }
    return Promise.reject(error);
  }
);

export default api;
