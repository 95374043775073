import Feedback from "react-bootstrap/esm/Feedback";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

interface IProps {
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  customMessage?: string;
}

const ErrorInput = ({ error, customMessage }: IProps) => {
  const getCustomMessage = () => {
    return customMessage ? customMessage : `Formato inválido`;
  }

  return (
    <Feedback tooltip type="invalid">
      {error?.type === "validate" ? getCustomMessage() : `${error?.message}`}
    </Feedback>
  );
};

export default ErrorInput;
