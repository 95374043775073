import { INotification } from "./Notification";
import { createEvent } from "react-event-hook";
import { useRef } from "react";

const createUUID = () => {
  const pattern = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";

  return pattern.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export enum TypeNotification {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  CHANGE = "danger",
  WARNING = "warning",
  INFO = "info",
  ERROR = "error",
}

namespace NotificationManager {
  //const listNotify = useRef<INotification[]>([]);
  const { emitMessage, useMessageListener } = createEvent("message")<INotification>();

  const create = (notify: INotification) => {
    const defaultNotify = {
      id: createUUID(),
      type: "info",
      title: null,
      message: null,
      timeOut: 5000,
      customClassName: "",
    };
    /*const _aux = listNotify.current;

    if (notify.priority) {
      _aux.unshift(Object.assign(defaultNotify, notify));
    } else {
      _aux.push(Object.assign(defaultNotify, notify));
    }
    listNotify.current = _aux;*/
    emitMessage(Object.assign(defaultNotify, notify));
  };

  export const primary = (
    message: string,
    title?: string,
    timeOut?: number,
    onClick?: Function,
    priority?: boolean,
    customClassName?: string
  ) => {
    create({
      type: TypeNotification.PRIMARY,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  };

  export const secondary = (
    message: string,
    title?: string,
    timeOut?: number,
    onClick?: Function,
    priority?: boolean,
    customClassName?: string
  ) => {
    create({
      type: TypeNotification.SECONDARY,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  };

  export const info = (
    message: string,
    title?: string,
    timeOut?: number,
    onClick?: Function,
    priority?: boolean,
    customClassName?: string
  ) => {
    create({
      type: TypeNotification.INFO,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  };

  export const success = (
    message: string,
    title?: string,
    timeOut?: number,
    onClick?: Function,
    priority?: boolean,
    customClassName?: string
  ) => {
    create({
      type: TypeNotification.SUCCESS,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  };

  export const warning = (
    message: string,
    title?: string,
    timeOut?: number,
    onClick?: Function,
    priority?: boolean,
    customClassName?: string
  ) => {
    create({
      type: TypeNotification.WARNING,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  };

  export const error = (
    message: string,
    title?: string,
    timeOut?: number,
    onClick?: Function,
    priority?: boolean,
    customClassName?: string
  ) => {
    create({
      type: TypeNotification.ERROR,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  };

  /*remove(notification: INotification) {
    this.listNotify = this.listNotify.filter((n) => notification.id !== n.id);
    this.emitChange();
  }

  emitChange() {
    this.emit(TypeNotification.CHANGE, this.listNotify);
  }*/

  export const addChangeListener = (callback: Function) => {
    /*useMessageListener((notification) => {
      callback(notification)
    });*/
  }
  
  export const removeChangeListener = (callback: Function) => {
    //this.removeListener(TypeNotification.CHANGE, callback);
  }
}

export default NotificationManager;
