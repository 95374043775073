import { Button, OverlayTrigger, Popover, Image } from "react-bootstrap";

const FindInfo = () => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Chave de acesso</Popover.Header>
      <Popover.Body>
        <Image
          src="./images/nota-fiscal.jpg"
          className="img-fluid"
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="helper_btn mb-3">
      <OverlayTrigger trigger="click" placement="top" overlay={popover}>
        <Button variant="warning" className="btn-sm w-100">
          ONDE ENCONTRO ESSA INFORMAÇÃO?
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default FindInfo;
