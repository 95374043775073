import { Table } from "react-bootstrap";

import { ItemNumber } from "./ItemNumber";
import { IDrawNumber } from "../../../interfaces/IDrawNumber";

interface IProps {
  itens: IDrawNumber[];
}

export const ListNumbers = ({ itens }: IProps) => {
  return (
    <div className="table-holder">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Número</th>
            <th>Criado em</th>
          </tr>
        </thead>
        <tbody>
          {itens.map((item) => (
            <ItemNumber item={item} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};
