import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from 'react-bootstrap/Alert';
import { IInvoiceCreatedInfo } from "../../../interfaces/IInvoiceCreatedInfo";

interface IProps {
  showModal: boolean;
  info?: IInvoiceCreatedInfo;
  onClose: () => void;
}

const Informations = ({ info, onClose, showModal }: IProps) => {
  if (!info) return null;

  return (
    <Modal size="lg" show={showModal} onHide={() => onClose()} className="modal-fiscalcode">
      <Modal.Header closeButton>
        <Modal.Title>DADOS DA NOTA CADASTRADA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
          <Alert variant="success">
            <strong> {info.invoiceGameChances} Novas Caixas da Sorte</strong>
          </Alert>

          <hr />
          <div className="result-box ">
            <h3>Informações da nota fiscal</h3>
            <p><b>Valor:</b> R$ {info.invoice.invoceValue}</p>

            <h4>Multiplicadores:</h4>
            <p>
              <b>* Cartão da Promoção:</b> {info.invoice.hasCreditcard ? "Sim" : "Não"}
            </p>
            <p>
              <b>* Produto participante:</b> {info.invoice.hasProduct ? "Sim" : "Não"}
            </p>
            {info.invoice.hasProduct && (
              <ul>
                {info.products.map((el) => (
                  <li>{`${el.description} - ${el.brand}`}</li>
                ))}
              </ul>
            )}

          
            
            <h4>Números da Sorte</h4>
            {info.drawNumbers.length > 0 && (
              <ul>
                {info.drawNumbers.map((el) => (
                  <li>{`${el.number}`}</li>
                ))}
              </ul>
            )}

          <Alert variant="info">
            Você já tem um total de <b>{info.totalInvoices}</b> Notas cadastradas
          </Alert>

          {/* <h4 className="title tilte_box">CAIXAS DE PRESENTE</h4>
          <div className="alert alert-info" role="alert">
            <p>Você tem {info.totalInvoices} Notas cadastradas</p>
            <p>e {info.totalGameChances} Caixas de Presente</p>
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Informations;
