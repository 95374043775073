import ThemeProvider from "react-bootstrap/ThemeProvider";
import { Route, Routes } from "react-router-dom";
import { NotFoundPage } from "./pages/Erros";
import HomePage from "./pages/Home";
import RulesPage from "./pages/Rules";
import GamePage from "./pages/Game";
import RegisterPage from "./pages/Register";
import WinnersPage from "./pages/Winners";
import CampaignNumbers from "./pages/CampaignNumbers";
import React from "react";
import "./App.scss";
import NavbarWithAnimation from "./components/NavbarWithAnimation";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <React.Fragment>
      {/*<Alert title={'teste'} type="success" message={'teste'} />
      <NotificationContainer />*/}
      <ThemeProvider
        breakpoints={["xxl", "xl", "lg", "md", "sm", "xs"]}
        minBreakpoint="xs"
      >
        <NavbarWithAnimation />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/meus-numeros-da-sorte" element={<CampaignNumbers />} />
          <Route path="/ganhadores" element={<WinnersPage />} />
          <Route path="/regulamento" element={<RulesPage />} />
          <Route path="/jogo" element={<GamePage />} />
          <Route path="/cadastro" element={<RegisterPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ThemeProvider>
      <CookieConsent
        location="bottom"
        buttonText="Concordo"
        cookieName="varejo-da-sorte-atakarejo-2024"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Este site usa cookies para melhorar a experiência do usuário.
      </CookieConsent>
    </React.Fragment>
  );
}

export default App;
