import { FieldValues, useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { IClient } from "../../../interfaces/IClient";
import TextInput from "../../../components/form/TextInput";
import NumberValidator from "../../../validators/NumberValidator";
import { Scanner, useDevices } from "@yudiel/react-qr-scanner";
import { useState } from "react";
import InvoiceService from "../../../services/InvoiceService";
import { IWithMessageInfo } from "../../../interfaces/IWithMessage";
import { IInvoiceCreatedInfo } from "../../../interfaces/IInvoiceCreatedInfo";
import FindInfo from "./FindInfo";

interface IRegister {
  client: IClient;
  onSubmitHandler: Function;
}

export const RegisterForm = ({ client, onSubmitHandler }: IRegister) => {
  const [isLoading, setIsLoading] = useState(false);
  const [readQRCode, setReadQRCode] = useState(false);
  const devices = useDevices();
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    control,
    //formState: { errors },
  } = useForm({
    defaultValues: {
      fiscalCode: "",
    },
    shouldUseNativeValidation: true,
    shouldFocusError: true,
  });

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);
    const params = {
      token: client.token,
      fiscalCode: data["fiscalCode"],
    };
    const res = await InvoiceService.add(params);
    if (res.success) {
      reset();
      setIsLoading(false);
      onSubmitHandler(res?.body as IInvoiceCreatedInfo);
    } else {
      const keys = Object.keys(res.namespacedMessages);

      if (keys.length === 0) {
        setError("fiscalCode", { message: res.globalMessages[0].defaultMessage ?? 'Não foi possível salvar a nota fiscal', type: "value" });
      }
      else {
        keys.forEach((el) => {
          const errors = res.namespacedMessages[el] as IWithMessageInfo[];
          if (errors.length > 0) {
            const error = errors[0];
            const key = error.fieldName as "fiscalCode";
            setError(key, { message: error.defaultMessage, type: "value" });
          }
        });
      }
      setIsLoading(false);
    }
  };


  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className="form_note">
      {devices.length > 0 && readQRCode && (
        <div className="scan_window">
          <Scanner
            onScan={(result) => {
              const code = result[0].rawValue.split('=');
              let codeStr = "";
              if (code.length > 0) {
                codeStr = code[1];
              }
              else {
                codeStr = code[0];
              }

              codeStr = codeStr ? codeStr.split('|')[0] : 'QR Code não é de uma nota fiscal válida';

              setValue("fiscalCode", codeStr);
              setReadQRCode(false);
            }}
          />
        </div>
      )}
      <TextInput
        label="Chave de acesso"
        attribute="fiscalCode"
        register={register}
        control={control}
        //errors={errors}
        rules={{
          required: { value: true, message: "Campo obrigatório" },
          minLength: { value: 44, message: "Mínimo de 44 caracteres" },
          maxLength: {
            value: 50,
            message: "Máximo de 50 caracteres",
          },
          validate: (data: string) => {
            return !NumberValidator.onlyDigits(data)
              ? "Deve conter apenas números"
              : true;
          },
        }}
        maxLength={50}
        placeholder={"Código da nota fiscal"}
      />
      <FindInfo />

      <Row>
        {devices.length > 0 && (
          <Col>
            <Button
              variant="primary"
              onClick={() => setReadQRCode(!readQRCode)}
              className="btn-lg w-100 mt-2"
            >
              {readQRCode ? "Cancelar" : "Ler QR Code"}
            </Button>
          </Col>
        )}
        <Col>
          <Button
            variant="secondary"
            type="submit"
            className="btn-lg w-100 mt-2"
            disabled={isLoading}
          >
            {isLoading ? 'Enviando…' : 'ENVIAR'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

