import { useRef, useState } from "react";
import { Button, Container, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { IClient } from "../../interfaces/IClient";
import GameOpportunityService from "../../services/GameOpportunityService";
import WinnerComponent from "./components/WinnerComponent";
import TryAgainComponent from "./components/TryAgainComponent";
import { IGameOpportunity } from "../../interfaces/IGameOpportunity";
import FooterComponent from "../../components/FooterComponent";

const GamePage = () => {
  const { state } = useLocation();
  const client = useRef<IClient>(state.client as IClient);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [totalChances, setTotalChances] = useState<number>(
    client.current.countOpportunitties
  );
  const [showResult, setShowResult] = useState(false);
  const [winner, setWinner] = useState(false);

  const getTitle = () => {
    return `chance${totalChances !== 1 ? "s" : ""}`;
  };

  const onPlayGame = async () => {
    setIsLoading(true);
    const res = await GameOpportunityService.newGame(client.current.token);
    if (res.success) {
      const body = res.body as IGameOpportunity;
      setTotalChances(body.countOpportunitties);
      client.current.countOpportunitties = body.countOpportunitties;
      setWinner(body.isWinner || false);
      setShowResult(true);
    } else {
      alert("Ocorreu um erro no servidor.");
    }
    setIsLoading(false);
  };

  const onTryAgain = () => {
    setShowResult(false);
  };

  const onGoHome = () => {
    navigate("/");
  };

  return (
    <>
      {!showResult && totalChances == 0 && (
        <div className="menupush">
          <section className="sec hero hero_sec  hero_game text-center ">

            <div className="hero-image hero-image-top ">
              <div className="img-wrapper">
                <Image src="./images/guirlanda-top.png" className="" />
              </div>
              <div className="img-wrapper">
                <Image src="./images/guirlanda-top.png" className="" />
              </div>
              <div className="img-wrapper">
                <Image src="./images/guirlanda-top.png" className="" />
              </div>
              <div className="img-wrapper">
                <Image src="./images/guirlanda-top.png" className="" />
              </div>
              <div className="img-wrapper">
                <Image src="./images/guirlanda-top.png" className="" />
              </div>
            </div>

            <div className="form_border">
              <h2 className="sec-title text-center form_title" id="not-numbers">
                Você não tem mais chances
              </h2>
              <div className="button-content">
                <div className="button-content-inner">
                  <Button
                    variant="primary"
                    className="btn-lg w-100 mt-2"
                    onClick={() => onGoHome()}
                  >
                    Volta para a página inicial
                  </Button>
                </div>
              </div>
            </div>

          </section>
        </div>
      )}
      {(showResult || totalChances > 0) && (
        <div className="menupush">
          <section className="sec hero hero_sec  hero_game text-center ">

            <div className="hero-image hero-image-top ">
              <div className="img-wrapper">
                <Image src="./images/guirlanda-top.png" className="" />
              </div>
              <div className="img-wrapper">
                <Image src="./images/guirlanda-top.png" className="" />
              </div>
              <div className="img-wrapper">
                <Image src="./images/guirlanda-top.png" className="" />
              </div>
              <div className="img-wrapper">
                <Image src="./images/guirlanda-top.png" className="" />
              </div>
              <div className="img-wrapper">
                <Image src="./images/guirlanda-top.png" className="" />
              </div>
            </div>

            <div className="hero-inner">
              <Container className="position-relative">
                {/* HEADER */}
                <header className="hero-header">
                  <div className="logo_campaign ">
                    <Image src="./images/logo-campanha.png" className="img-fluid" />
                  </div>
                </header>

                {/* SE TIVER NÚMEROS   */}
                <div className="form_border game_wrapper">
                  <div id="has-numbers">
                    <h2 className="title sec-title text-center form_title">
                      {`Você tem ${totalChances} ${getTitle()}`}
                    </h2>
                    <div className="game-area">

                      <div className="container-game">
                        <div className="box">
                          <div className="box-body">
                            <div className="button-content">
                            {/*                             
                            <div className="alert alert-warning">
                              Sorteio indisponível no momento. Por favor, retorne mais tarde.
                            </div>
                            */}
                            
                            {!showResult && (
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn-lg w-100 mt-2"
                                onClick={() => onPlayGame()}
                              >
                                Tentar a sorte
                              </Button>
                            )}
                            

                            </div>
                          </div>
                          {showResult && winner && <WinnerComponent />}
                          {showResult && !winner && <TryAgainComponent />}
                        </div>

                      </div>

                      {showResult && winner && (
                        <div className="alert alert-info mt-4 end-game-content">
                          <p>
                            O código do seu Vale-compras foi enviado para o seu
                            e-mail.
                          </p>
                          <p>
                            Em no máximo <strong>15 minutos</strong> você receberá o
                            seu prêmio.
                          </p>
                          <p>
                            Caso não receba, por favor, confira a caixa de <b>SPAM</b> ou
                            entre em contato com nossos canais de comunicação.
                          </p>
                        </div>
                      )}
                      {showResult && totalChances > 0 ? (
                        <div className="button-content">
                          <div className="button-content-inner">
                            <Button
                              variant="secondary"
                              type="submit"
                              className="btn-lg w-100 mt-2"
                              onClick={() => onTryAgain()}
                              disabled={isLoading}
                            >
                              {isLoading ? "Enviando…" : "Tentar novamente"}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="button-content">
                          <Button
                            variant="secondary"
                            className="btn-lg w-100 mt-2"
                            onClick={() => onGoHome()}
                          >
                            Volta para a página inicial
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </div>
      )}
      <FooterComponent />
    </>
  );
};

export default GamePage;
