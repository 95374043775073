import { Col, Container, Image, Row, Accordion } from "react-bootstrap";
import PageContentService from "../../../services/PageContentService";
import { useEffect, useState } from "react";
import { IPageContent } from "../../../interfaces/IPageContent";
import PageContent from "../../../components/PageContent";


const FaqComponent = () => {
  

  return (
    <section id="faq" className="sec sec_faq">
      <div className="overlay top-lights">
        <Image src="./images/faq-top-bg-luz.png" alt="" className="" />
      </div>
      <Container>
        <div className="sec_top-images">

          <div className="faq-prize prize-1">
            <Image
              src="./images/faq-bola-50mil.png"
              className="animate animate-rotate img-fluid"
            /> 
          </div>
          <div className="faq-prize prize-2">
            <Image
              src="./images/faq-bolao-1milhao.png"
              className="animate animate-rotate img-fluid"
            />
          </div>
          
        </div>
        <div className="sec_content">
          <header className="sec_header mb-4">
            <h2 className="title sec_title">PERGUNTAS FREQUENTES</h2>
          </header>
          <Row>
            <Col lg="10">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Do que eu preciso para concorrer?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>Para participar da promoção <b>“Mega Fim de Ano Atakarejo”</b>, o cliente deve informar seu CPF no início da compra, efetuarem compras a partir de R$ 100,00 (cem reais) nas lojas Atakarejo participante da promoção, contendo ao menos um produto das marcas participantes da promoção e efetuar o cadastro na promoção através do Web App <a href="https://www.fimdeanoatakarejo.com.br" target="_blank">www.fimdeanoatakarejo.com.br</a>.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Posso cadastrar uma nota de qualquer data de compra?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      As compras feitas no período de 10 de novembro de 2024 até o dia 04 de janeiro de 2025. Poderá ser contemplado com um dos brindes da promoção.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Posso me cadastrar mais de uma vez?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>Não haverá limites de chances por participante, podendo cada participante concorrer com quantas chances tiver direito, desde que atenda as condições previstas no regulamento.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Qual o prêmio e como eu posso utilizá-lo?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>O prêmio é de 01 (um) Vale-compras Atakarejo no valor de R$ 500,00 (quinhentos reais), sem função de saque, vinculado ao CPF do participante e 01 (um) prêmio de R$ 50 mil (cinquenta mil reais).
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    Pessoas de qualquer idade podem participar?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>Qualquer pessoa física com idade igual ou superior a 18 (dezoito) anos.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Ganhei o vale compras mais quero dar para outra pessoa, é possível?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>Ficam os participantes cientes desde já, que a participação na promoção é individualizada, e não poderá, em hipótese alguma, transferir e/ou dividir com outro participante qualquer valor residual, independentemente do grau de parentesco e/ou amizade. Da mesma forma, não será admitida, por força de legislação fiscal, “divisão de valores de notas fiscais” entre participantes no ato da compra.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default FaqComponent;
