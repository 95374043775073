import { Col, Container, Row, Image } from "react-bootstrap";
import { SelectClientForm } from "./components/SelectClientForm";
import { useState } from "react";
import { ListNumbers } from "./components/ListNumbers";
import FooterComponent from "../../components/FooterComponent";
import { IClient } from "../../interfaces/IClient";
import DrawNumberService from "../../services/DrawNumberService";
import { IDrawNumber } from "../../interfaces/IDrawNumber";
import ClientService from "../../services/ClientService";

const CampaignNumbersPage = () => {
  const [list, setlist] = useState<IDrawNumber[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState<IClient | undefined>();

  /*useEffect(() => {
    const storage = localStorage.getItem(CLIENT_STORAGE);
    let _client: any = undefined;
    if (storage && Object.keys(storage).length > 0) {
      _client = JSON.parse(storage) as IClient;
      if (_client.token) return;

      setClient(_client);
      setIsLoading(true);

      DrawNumberService.getByClientToken(_client.token).then((res) => {
        setlist(res.success ? (res.body as IDrawNumber[]) : []);
        setIsLoading(false);
      });
    }
  }, []);*/

  const onSubmitHandler = (cpf: string) => {
    setIsLoading(true);

    ClientService.getClientByCpf(cpf).then((res) => {
      if (res.success) {
        var _client = res.body as IClient;
        setClient(_client);

        DrawNumberService.getByClientToken(_client.token).then((res) => {
          setlist(res.success ? (res.body as IDrawNumber[]) : []);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_numbers">
          <div className="hero-image hero-image-top ">
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
          </div>


          <div className="hero-inner">
            <Container className="position-relative">
              <header className="hero-header">
                <div className="logo_campaign ">
                  <Image src="./images/logo-campanha.png" className="img-fluid" />
                </div>
                <div className="rewards d-none d-md-flex  align-items-start overflow-hidden">
                  <Image src="./images/bola-1.5m.png"  srcSet="./images/bola-1.5m@2x.png" className="ball ball-15" />                   
                  <Image src="./images/bola-1m.png" srcSet="./images/bola-1m@2x.png"  className="ball ball-1" />
                  <Image src="./images/bola-50mil.png" srcSet="./images/bola-50mil@2x.png"  className="ball  ball-50" />
                </div>
              </header>
              
                <Row>
                  <Col md="8" className="mx-auto">
                    <SelectClientForm
                      onSubmitHandler={(cpf) => onSubmitHandler(cpf)}
                      isLoading={isLoading}
                    />

                    {client && (
                    <div className="container-numbers mt-4">
                      {list.length > 0 ? <ListNumbers itens={list} /> : `Nenhum número criado`}
                    </div>
                    )}
                  </Col>
                </Row>
              <div className="rewards align-items-start d-flex d-md-none overflow-hidden">
                  <Image src="./images/bola-1.5m.png"  srcSet="./images/bola-1.5m@2x.png" className="ball ball-15" />                   
                  <Image src="./images/bola-1m.png" srcSet="./images/bola-1m@2x.png"  className="ball ball-1" />
                  <Image src="./images/bola-50mil.png" srcSet="./images/bola-50mil@2x.png"  className="ball  ball-50" />
              </div>

              
            </Container>
          </div>

          

        </section>
      </div>
      <FooterComponent />
    </>
  );
};

export default CampaignNumbersPage;
