import { Col, Container, Image, Row } from "react-bootstrap";

const BrandsComponent = () => {
  return (
    <section id="marcas-participantes" className="sec sec_brands">
      <div className="sec_top-images">
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
      </div>
      <Container>
        <div className="d-md-flex ">
          <header className="sec_header text-center text-md-start">
            <h2 className="title sec_title">Nossos Parceiros</h2>
            <div className="logo-wrapper">
              <Image src="./images/logo-brands.png" className="brands_logo" />            
            </div>
          </header>

          <div className="marcas-wrapper brands">
                {/* <!-- DIAMANTE --> */}
                <div className="diamante">
                  <div className="g-3">
                    <div className="marca-item">
                          <Image src="./images/marcas/diamante/itaipava-premium.png" className="logo lazyload g-4" />
                        </div>
                    </div>

                    <div className="g-3">
                        <div className="marca-item brand_item">
                          <Image src="./images/marcas/diamante/tnt.png" className="logo lazyload g-4" />
                        </div>
                    </div>

                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/ala.png" className="logo lazyload g-4" />
                        </div>
                    </div>

                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/seda.png" className="logo lazyload g-4" />
                        </div>
                    </div>        
                    
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/sadia.png" className="logo lazyload g-4" />
                        </div>
                    </div>
                    
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/vitarella.png" className="logo lazyload g-4" />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/piraque.png" className="logo lazyload g-4" />
                        </div>
                    </div>

                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/diamante/ninho.png" className="logo lazyload g-4" />
                        </div>
                    </div>
                    
                </div>

                {/* <!-- OURO --> */}
                <div className="ouro">
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/ouro/sao-braz.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/ouro/seara.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                          <Image src="./images/marcas/ouro/fofura-baby.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/coca.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/ype.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/neve.png" className="logo lazyload " />
                        </div>
                    </div>                   
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/marilan.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/brahma.png" className="logo lazyload " />
                        </div>
                    </div>                    
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/yoki.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-3">
                        <div className="marca-item">
                        <Image src="./images/marcas/ouro/ekobom.png" className="logo lazyload " />
                        </div>
                    </div>
                </div>
                {/* <!-- PRATA --> */}
                <div className="prata">

                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/pantene.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/camil.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/francis.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/ox.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/betania.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/batavo.png" className="logo lazyload " />
                      </div>
                  </div>

                  {/* 2 */}
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/marata.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/nestle-grego.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/mauricea.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/petybon.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/johnson-baby.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/baygon.png" className="logo lazyload " />
                      </div>
                  </div>

                  {/* 3 */}
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/bemtevi.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/baly.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/amstel.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/melitta.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/santa-clara.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/heinz.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/yopro.png" className="logo lazyload " />
                      </div>
                  </div>                
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/aurora.png" className="logo lazyload " />
                      </div>
                  </div>
                  <div className="g-2">
                      <div className="marca-item">
                        <Image src="./images/marcas/prata/colgate.png" className="logo lazyload " />
                      </div>
                  </div>

                </div>

                {/* <!-- BRONZE --> */}
                <div className="bronze">
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/rj-alimentos.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/above.png" className="logo lazyload " />
                        </div>
                    </div>                    
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/dragao.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/enerup.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/nestle-sorvetes.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/elseve.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/italac.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/slight.png" className="logo lazyload " />
                        </div>
                    </div>

                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/herbissimo.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/sempre.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/qboa.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/elefante.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/condor.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/babysec.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/ccgl.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/uauingleza.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/dentalclean.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/plasutil.png" className="logo lazyload " />
                        </div>
                    </div>
                    <div className="g-1">
                        <div className="marca-item">
                          <Image src="./images/marcas/bronze/bauducco.png" className="logo lazyload " />
                        </div>
                    </div>

                </div>
            </div>

        </div>
      </Container>
      <div className="sec_bottom-images">
        <div className="img-wrapper left">
          <Image src="./images/guirlanda-borda.png" className="img-fluid" />
        </div>
        <div className="img-wrapper right">
          <Image src="./images/guirlanda-borda.png" className="img-fluid" />
        </div>
      </div>
    </section>
  );
};

export default BrandsComponent;
