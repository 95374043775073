import { NotificationManager } from "../components/notifications";
import api from "../http-common";
import { IDrawNumber } from "../interfaces/IDrawNumber";
import { IWithMessage } from "../interfaces/IWithMessage";

const endpointPath: string = "/v1/draw-number";

namespace DrawNumberService {
  export const getByClientToken = async (token: string) => {
    try {
      const resp = await api.get(
        `${endpointPath}/find-by-client-token/${token}`
      );

      return resp?.data as IWithMessage<IDrawNumber[]>;
    } catch (e) {
      NotificationManager.error(
        "Erro no servidor ao tentar recuperar os números.",
        "Erro ao recuperar",
        3000
      );
      return e as IWithMessage<string>;
    }
  };
}
export default DrawNumberService;
