import { Alert } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

const WinnerComponent = () => {
  return (
    <div className="end-game win end-game-content">
      <div className="animation">
        <video src="./videos/ganhou.mp4" autoPlay muted width={`100%`}></video>
        {/* <Image src="./videos/ganhou.gif" /> */}
      </div>
      <div className="text-result">
        <Alert variant="success mt-1 rounded">
          VOCÊ GANHOU UM VALE COMPRA NO VALOR DE <strong>R$500,00</strong>.
        </Alert>
      </div>
    </div>
  );
};

export default WinnerComponent;
