import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { useLocation, useNavigate } from "react-router-dom";
import { RegisterForm } from "./components/RegisterForm";
import { useEffect, useRef, useState } from "react";
import { IClient } from "../../interfaces/IClient";
import { Alert, Button, Col, Row } from "react-bootstrap";
import ClientInfo from "./components/ClientInfo";
import ClientForm from "./components/ClientForm";
import { CLIENT_STORAGE } from "../../constants/ProjectConstants";
import Informations from "./components/Informations";
import { IInvoiceCreatedInfo } from "../../interfaces/IInvoiceCreatedInfo";
import FooterComponent from "../../components/FooterComponent";
import ClientService from "../../services/ClientService";

enum TypeClientForm {
  CLIENT_INFO,
  CLIENT_UPDATE,
}

const RegisterPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [client, setClient] = useState<IClient>(state.client);
  const [showInfoInvoiceModal, setShowInfoInvoiceModal] = useState(false);
  const [invoiceCreatedInfo, setInvoiceCreatedInfo] = useState<
    IInvoiceCreatedInfo | undefined
  >(undefined);

  const [typeClientForm, setTypeClientForm] = useState<TypeClientForm>(
    client && client.name
      ? TypeClientForm.CLIENT_INFO
      : TypeClientForm.CLIENT_UPDATE
  );

  useEffect(() => {
    if (!state.client || Object.keys(state.client).length === 0) {
      navigate("/");
    } else {
      ClientService.getClientByToken(state.client.token).then((res) => {
        if (res.success) setClient(res.body as IClient);
      });
    }
  }, []);

  const onNewRegisterHandler = (info: IInvoiceCreatedInfo) => {
    setClient((prev) => ({
      ...prev,
      countOpportunitties: info.totalGameChances,
    }));
    setInvoiceCreatedInfo(info);
    setShowInfoInvoiceModal(true);
  };

  const onUpdateInfoHandler = () => {
    setTypeClientForm(TypeClientForm.CLIENT_UPDATE);
  };

  const onClientUpdateHandler = (_client: IClient) => {
    localStorage.setItem(CLIENT_STORAGE, JSON.stringify(_client));
    setClient(_client);
    setTypeClientForm(TypeClientForm.CLIENT_INFO);
  };

  const onGoBackUpdateHandler = () => {
    setTypeClientForm(
      client && client.name
        ? TypeClientForm.CLIENT_INFO
        : TypeClientForm.CLIENT_UPDATE
    );
  };

  const onGoToGameHandler = () => {
    navigate("/jogo", { state: { client } });
  };

  console.log("[leo] RegisterPage: invoiceCreatedInfo", invoiceCreatedInfo);

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_cadastro  text-md-start">

          <div className="hero-image hero-image-top ">
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
          </div>

          <div className="hero-inner">
            <Container className="position-relative">

              <header className="hero-header">
                <div className="logo_campaign ">
                  <Image src="./images/logo-campanha.png" className="img-fluid" />
                </div>
                <div className="rewards d-none d-md-flex  align-items-start overflow-hidden">
                  <Image src="./images/bola-1.5m.png"  srcSet="./images/bola-1.5m@2x.png" className="ball ball-15" />                   
                  <Image src="./images/bola-1m.png" srcSet="./images/bola-1m@2x.png"  className="ball ball-1" />
                  <Image src="./images/bola-50mil.png" srcSet="./images/bola-50mil@2x.png"  className="ball  ball-50" />
                </div>
              </header>

              {!client.name && (
                <div className="cadastro_content form_pre-register">
                  
                    <h3 className="form_title title mb-4 text-center text-md-start">
                      Dados do cliente
                    </h3>

                    {typeClientForm === TypeClientForm.CLIENT_INFO && (
                      <ClientInfo
                        client={client}
                        onUpdateInfo={() => onUpdateInfoHandler()}
                      />
                    )}
                    {typeClientForm === TypeClientForm.CLIENT_UPDATE && (
                      <ClientForm
                        client={client}
                        onClientUpdate={(_client: IClient) =>
                          onClientUpdateHandler(_client)
                        }
                        onGoBack={() => onGoBackUpdateHandler()}
                      />
                    )}
                  
                </div>
              )}

              {client.name && (
                <Row className="cadastro_content">
                  <Col md="7" className="mb-5">
                    <div className="form_border">
                      <h2 className="form_title title mb-4 text-center text-md-start">
                        Preencha e Concorra!
                      </h2>
                      <RegisterForm
                        client={client}
                        onSubmitHandler={(info: IInvoiceCreatedInfo) =>
                          onNewRegisterHandler(info)
                        }
                      />
                      {((invoiceCreatedInfo?.totalGameChances || 0) > 0 ||
                        client.countOpportunitties > 0) && (
                        <Col className="mt-5">
                          <h3 className="title form_title text-center text-md-start">
                            {`Você possui ${
                              invoiceCreatedInfo
                                ? invoiceCreatedInfo.totalGameChances
                                : client.countOpportunitties
                            } sacos de presente te esperando`}
                          </h3>
                          <Button
                            variant="secondary"
                            onClick={() => onGoToGameHandler()}
                            className="btn-lg w-100 mt-2"
                          >
                            Abrir Saco de Prestente
                          </Button>
                          <Alert variant="info mt-1 d-none">
                            <strong>{`Você possui ${
                              invoiceCreatedInfo
                                ? invoiceCreatedInfo.totalGameChances
                                : client.countOpportunitties
                            } sacos de presente`}</strong>
                          </Alert>
                        </Col>
                      )}

                      <Informations
                        showModal={showInfoInvoiceModal}
                        info={invoiceCreatedInfo}
                        onClose={() => setShowInfoInvoiceModal(false)}
                      />
                    </div>
                  </Col>

                  <Col md="5">
                    <div className="form_border">
                      <h3 className="form_title title mb-4 text-center text-md-start">
                        Dados do cliente
                      </h3>

                      {typeClientForm === TypeClientForm.CLIENT_INFO && (
                        <ClientInfo
                          client={client}
                          onUpdateInfo={() => onUpdateInfoHandler()}
                        />
                      )}
                      {typeClientForm === TypeClientForm.CLIENT_UPDATE && (
                        <ClientForm
                          client={client}
                          onClientUpdate={(_client: IClient) =>
                            onClientUpdateHandler(_client)
                          }
                          onGoBack={() => onGoBackUpdateHandler()}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              )}

              <div className="rewards align-items-start d-flex d-md-none overflow-hidden">
                  <Image src="./images/bola-1.5m.png"  srcSet="./images/bola-1.5m@2x.png" className="ball ball-15" />                   
                  <Image src="./images/bola-1m.png" srcSet="./images/bola-1m@2x.png"  className="ball ball-1" />
                  <Image src="./images/bola-50mil.png" srcSet="./images/bola-50mil@2x.png"  className="ball  ball-50" />
              </div>

            </Container>
          </div>
          
        </section>
      </div>
      <FooterComponent />
    </>
  );
};

export default RegisterPage;
