import { useFormState } from "react-hook-form";
import { IInputProps } from "../../interfaces/IInputProps";
import { IModel } from "../../interfaces/IModel";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import ErrorInput from "./ErrorInput";

interface ICheckboxGroupInput extends IInputProps<IModel> {
    values: Array<{
        label: string;
        value: string | boolean | number;
        default?: boolean;
    }>,
    onChange?: (event: any) => void;
}

const CheckboxGroupInput = ({
    label,
    attribute,
    register,
    control,
    rules = {},
    size,
    placeholder,
    values,
    type,
    ...props
}: ICheckboxGroupInput) => {
    const [touched, setTouched] = useState(false);
    const { errors } = useFormState({ control });

    return (
        <Form.Group className="mb-3 position-relative" controlId={`form-${attribute}`}>
            {label && (<Form.Label>{label}</Form.Label>)}
            {values.map((el, index) => <div>
                <Form.Check
                    inline
                    label={<div className="d-inline" dangerouslySetInnerHTML={{ __html: el.label }}></div>}
                    isInvalid={touched && !!errors[attribute]}
                    onFocus={() => !touched ? setTouched(true) : null}
                    type='checkbox'
                    id={`${attribute}-radio-${index}`}
                    key={`${attribute}-radio-${index}`}
                    value={el.value.toString()}
                    {...register(attribute, rules)}
                    {...props}
                />
                
            </div>)}
            <ErrorInput error={errors[attribute]} />
        </Form.Group>
    );
};

export default CheckboxGroupInput;